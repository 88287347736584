/* eslint-disable no-restricted-globals */
import { useState } from 'react';
import {
	ButtonContainer,
	StyledArea,
	StyledError,
	StyledForm,
	StyledGap,
	StyledInfo,
	StyledLabel,
} from '../../styles/formularz';
import {
	MessageDiv,
	StyledArticle,
	StyledButton,
	StyledSelect,
} from '../../styles';

import { Layout } from '../../components/Layout';
import { StyledH2 } from '../../styles/common';
import { useAuth } from '../../hooks/useAuth';
import { SEO } from '../../components/SEO';
import axios from '../../config/axios';

export function Head() {
	return (
		<SEO
			title="Kontakt do kierowników"
			description="Formularz kontaktowy do kierowników."
		/>
	);
}

const isBrowser = () => typeof window !== 'undefined';

function FormularzPage({ data }: any) {
	const [message, setMessage] = useState('');
	const [destination, setDestination] = useState('1');
	const [error, setError] = useState('');
	const [info, setInfo] = useState('');

	const auth = isBrowser()
		? useAuth()
		: { user: '', role: '', verifyUser: () => {}, signOut: () => {} };

	if (!auth.user) {
		return null;
	}

	const isValidForm = () => {
		let err = '';

		if (message.length === 0) {
			err = `${err}Nieprawidłowa wiadomość. `;
		}

		if (err) {
			setError(`Formularz zawiera błędy: ${err}`);
			scroll(0, 0);
			return false;
		}
		setError('');
		return true;
	};

	const sendForm = async () => {
		if (isValidForm()) {
			try {
				const response = await axios.post('/send_form_2.php', {
					user: auth.user,
					destination,
					message,
				});
				if (response?.data?.ok === 1) {
					setInfo('Formularz został wysłany do kierownika. Dziękujemy.');
					setMessage('');
					scroll(0, 0);
				} else {
					setError(
						'Wystąpił błąd przy wysyłce formularza. Przepraszamy za utrudnienia.'
					);
					scroll(0, 0);
				}
			} catch (e) {
				setError(
					'Wystąpił błąd przy wysyłce formularza. Przepraszamy za utrudnienia.'
				);
				scroll(0, 0);
			}
		}
	};

	return (
		<Layout>
			<StyledArticle color="info">
				<StyledH2 as="h1" color="info">
					Kontakt do kierowników
				</StyledH2>
				<MessageDiv>
					<StyledForm>
						{info ? (
							<StyledForm>
								<StyledInfo>{info}</StyledInfo>
							</StyledForm>
						) : null}
						{error ? <StyledError>{error}</StyledError> : <StyledGap />}
						<StyledLabel htmlFor="to">Do kierownika:</StyledLabel>
						<StyledSelect
							style={{ marginLeft: 0 }}
							id="destination"
							name="destination"
							value={destination}
							onChange={(e) => setDestination(e.target.value)}
						>
							<option value="1">Kierownik Zakładu Eksploatacji</option>
							<option value="2">Kierownik Działu Eksploatacji Autobusów</option>
							<option value="3">Kierownik Działu Eksploatacji Tramwajów</option>
						</StyledSelect>
						<StyledLabel htmlFor="message">Wiadomość:</StyledLabel>
						{/* <StyledInfoText>
						</StyledInfoText> */}
						<StyledArea
							autoCapitalize="none"
							autoCorrect="off"
							id="message"
							name="message"
							spellCheck="false"
							value={message}
							onChange={(e) => {
								setMessage(e.target.value);
							}}
						/>
						<ButtonContainer>
							<StyledButton
								type="button"
								onClick={() => {
									sendForm();
								}}
							>
								Wyślij
							</StyledButton>
						</ButtonContainer>
					</StyledForm>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
}
export default FormularzPage;
